export function formatDateToPolish(dateString: string): string {
  const date = new Date(dateString);

  const options: Intl.DateTimeFormatOptions = {
    day: 'numeric',
    month: 'long',
    hour: '2-digit',
    minute: '2-digit',
  };

  return new Intl.DateTimeFormat('pl-PL', options).format(date);
}

export function formatDateToPolishLong(dateString: string): string {
  const date = new Date(dateString);

  const options: Intl.DateTimeFormatOptions = {
    day: 'numeric',
    month: 'long',
    year: 'numeric',
  };

  return new Intl.DateTimeFormat('pl-PL', options).format(date);
}

export function formatDate(date: Date): string {
  const options: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
  };

  return new Intl.DateTimeFormat('en-CA', options).format(date);
}

export function formatTime(date: Date) {
  const hours = date.getHours().toString().padStart(2, '0');
  const minutes = date.getMinutes().toString().padStart(2, '0');
  return `${hours}:${minutes}`;
}

export function isToday(date: Date) {
  const now = new Date();
  return (
    date.getFullYear() === now.getFullYear() &&
    date.getMonth() === now.getMonth() &&
    date.getDate() === now.getDate()
  );
}

export function isYesterday(date: Date) {
  const now = new Date();
  return (
    date.getFullYear() === now.getFullYear() &&
    date.getMonth() === now.getMonth() &&
    date.getDate() === now.getDate() - 1
  );
}

export function calculateDaysDiff(date: Date) {
  const now = new Date();
  const timeDiff = now.getTime() - date.getTime();
  return Math.floor(timeDiff / (1000 * 60 * 60 * 24));
}

export function formatRelativeDate(dateString: string) {
  const inputDate = new Date(dateString);

  if (isToday(inputDate)) {
    return 'Dziś';
  } else if (isYesterday(inputDate)) {
    return 'Wczoraj';
  } else {
    return `${formatDateToPolishLong(inputDate.toString())}`;
  }
}

export function isDateBefore(date1: string, date2: string): boolean {
  const date1Parsed = new Date(date1);
  const date2Parsed = new Date(date2);

  if (isNaN(date1Parsed.getTime()) || isNaN(date2Parsed.getTime())) {
    console.log('date1', date1);
    console.log('date2', date2);

    throw new Error('Invalid date format');
  }

  return date1Parsed < date2Parsed;
}
